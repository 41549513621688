import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'

import Layout from '../components/layout'

import * as styles from './index.module.scss'
import 'swiper/scss'
import 'swiper/scss/pagination'

const IndexPage = () => {
  return (
    <Layout
      headerOverContent={true}
    >
      <Swiper
        // loop={true}
        // modules={[
        //   Pagination,
        //   Autoplay
        // ]}
        // pagination={{
        //   clickable: true,
        // }}
        // autoplay={{
        //   delay: 4500,
        //   disableOnInteraction: false,
        // }}
        className={styles.slider}
      >
        {/* 
        <SwiperSlide className={styles.slide}>
          <Link to="/sheets/">
            <StaticImage
              src="../images/main/background-1.jpg"
              className={styles.promoImage}
              alt="Stretch Jercy Стиль"
            />
            <h2 className={styles.caption}>
              Стиль <i>Простыни</i>
            </h2>
          </Link>
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          <Link to="/kids/">
            <StaticImage
              src="../images/main/background-2.jpg"
              className={styles.promoImage}
              alt="Stretch Jercy Уют"
            />
            <h2 className={styles.caption}>
              Уют <i>Дети</i>
            </h2>
          </Link>
        </SwiperSlide> 
        */}
        <SwiperSlide className={styles.slide}>
          {/* 
          <Link to="/plaids/"> 
          */}
            <StaticImage
              src="../images/main/background-0.jpg"
              className={styles.promoImage}
              alt="Stretch Jercy Пледы"
            />
            {/* 
            <h2 className={styles.caption}>
              Качество <i>Пледы</i>
            </h2>
          </Link> 
          */}
        </SwiperSlide>
      </Swiper>
      <article className={styles.text}>
        <p>
          Добро пожаловать на официальную страницу <b>Stretch Jersy</b>!
        </p>
        <p>
          Мы — современная российская производственная компания, специализирующаяся на создании постельных принадлежностей с уникальным фирменным дизайном. Вся наша продукция создаётся на импортном оборудовании с использованием новейших текстильных технологий. Главной целью компании является создание комфортных условий для вашего сна. Поэтому все изделия торговой марки <b>Stretch Jersy</b> по умолчанию гипоаллергенны, изготовлены из высококачественных натуральных материалов и сертифицированы.
        </p>
        <p>
          У нас большой ассортимент постельных принадлежностей со множеством цветов и размеров, который постоянно расширяется. Вы всегда сможете подобрать продукцию, подходящую под ваши потребности и вкусы. Благодаря торговле на самых популярных интернет-площадках, у нас сформирована обширная база клиентов по всей России. Нам важно сделать ваш сон максимально здоровым, уютным и спокойным. Надеемся, что вы тоже станете нашим постоянным покупателем.
        </p>
      </article>
    </Layout>
  )
}

export default IndexPage
